import { FeatureName } from './index';

export default [
  'admin.hammer-configuration',
  'admin.screen-configuration',
  'ecom.improve-search',
  'fleet.alarm-signal-intervals',
  'fleet.claims',
  'fleet.dashboard',
  'fleet.interpolate-samples-server-side',
  'fleet.liner-wear',
  'fleet.visualisation-tabular-data',
  'new-data-grid',
] as const;

export const labels: Record<FeatureName, string> = {
  'admin.hammer-configuration': 'Hammer configuration (Ärdy)',
  'admin.screen-configuration': 'Screen configuration',
  'ecom.improve-search': 'Ecom improve search',
  'fleet.alarm-signal-intervals': 'Alarm thresholds',
  'fleet.claims': 'Warranty claims',
  'fleet.dashboard': 'Equipment dashboard',
  'fleet.interpolate-samples-server-side':
    'Identify gaps in chart data on server',
  'fleet.liner-wear': 'Liner wear chart for cone crushers',
  'fleet.visualisation-tabular-data': 'Tabular data of line chart samples',
  'new-data-grid': 'New table',
};
