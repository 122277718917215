import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from '../../app/routing/NotFound';
import { catalogsFullPath } from '../../pathDefinitions';

const CatalogPage = lazy(() => import('./CatalogPage'));
const Catalog = lazy(() => import('./content/Catalog'));

export const catalogRoute = (catalogId: number) =>
  `${catalogsFullPath}/${catalogId}`;
export const catalogIdParam = 'catalogId';

export default () => (
  <Routes>
    <Route path="/" element={<CatalogPage />} />
    <Route path={`:${catalogIdParam}`} element={<Catalog />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
