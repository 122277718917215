// All user role id's and names, sorted by permission level.
// Some roles exists twice in this list as they have different id's in Dev and Prod.
export default [
  {
    id: 'a15dd567-7d8d-40f9-9d33-ebe51280dc23',
    name: 'SAM Admin',
  },
  {
    id: 'ba3b8d48-a7da-49ef-8508-0b7a6b9ad630',
    name: 'Super Admin',
  },
  {
    id: '5146fcc4-1bb7-4989-a646-4b841684c613',
    name: 'Frontline',
  },
  {
    id: '4bf889a3-6478-4663-9dcf-f34d1c66d05d',
    name: 'Frontline',
  },
  {
    id: 'c002d62d-dd99-444f-991f-587eebbf8731',
    name: 'Edge Factory Admin',
  },
  {
    id: '0897de64-1433-4aee-b744-b8f4497cbb94',
    name: 'Edge Factory User',
  },
  {
    id: '3eab48f3-1b43-45b3-927f-dacf5745c3be',
    name: 'Dealer Admin',
  },
  {
    id: '8e4f737d-8510-4610-9733-5a10a297d769',
    name: 'End Customer Admin',
  },
  {
    id: 'bd08b967-9a78-45fd-a0d6-6f0466eff440',
    name: 'Super User',
  },
  {
    id: 'c847c689-9ef1-4d81-b113-c08e18707075',
    name: 'Purchaser',
  },
  {
    id: 'e28f713f-fee1-4939-b2c5-3f3ecdfa9f64',
    name: 'Purchaser',
  },
  {
    id: 'c4b5eee7-cacd-4ed7-9f1a-347639a058af',
    name: 'Standard User',
  },
  {
    id: '84e8adbb-6313-49ff-b3fa-ed18ec95164f',
    name: 'Read-only User',
  },
  {
    id: 'a0ae0831-3242-45ef-a312-9aeaeaa8b839',
    name: 'Beta Tester',
  },
];
