import { toSxArray } from '@/theme/util';
import { Skeleton, SkeletonProps } from '../Skeleton';

export interface SelectSkeletonProps extends SkeletonProps {
  hasLabel?: boolean;
  margin?: 'none' | 'dense';
}

const SelectSkeleton = ({
  hasLabel = true,
  margin = 'dense',
  sx,
  ...rest
}: SelectSkeletonProps) => (
  <Skeleton
    variant="rectangular"
    sx={[
      {
        mt: margin === 'dense' ? 1 : 0,
        mb: margin === 'dense' ? 0.5 : 0,
        borderRadius: 1,
        width: '100%',
        height: hasLabel ? '53px' : '37px',
      },
      ...toSxArray(sx),
    ]}
    {...rest}
  />
);

export default SelectSkeleton;
