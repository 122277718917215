import { SubtotalInfo } from '@/feature/article/useSubtotalInfo';

// For a complete list of pre-defined Google Analytics events, see
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events
// Sam-specific events are prefixed with 's_'.
const nativeEvents = ['add_to_cart', 'begin_checkout', 'purchase', 'search'];

export const track = (eventName: string, eventParameters?: any) => {
  const name = (nativeEvents.includes(eventName) ? '' : 's_') + eventName;
  //@ts-ignore
  window.gtag('event', name, eventParameters);
};

export const trackUser = (userProperties: any) => {
  //@ts-ignore
  window.gtag('set', 'user_properties', userProperties);
};

export const trackAddToCart = (items: CartItem[]) =>
  track('add_to_cart', {
    items: toGaItems(items),
  });

export const trackBeginPurchase = (
  subtotalInfo: SubtotalInfo,
  items: CartItemDto[]
) =>
  track('begin_checkout', {
    value: subtotalInfo.subtotal,
    currency: subtotalInfo.currency,
    items: toGaItems(items),
  });

type ChannelType = 'punchout';

interface TrackOptions {
  channel: ChannelType;
}

export const trackPurchase = (
  subtotalInfo: SubtotalInfo,
  items: CartItemDto[],
  options?: TrackOptions
) =>
  track('purchase', {
    value: subtotalInfo.subtotal,
    currency: subtotalInfo.currency,
    items: toGaItems(items),
    ...options,
  });

const toGaItems = (items: (CartItem | CartItemDto | any)[]) =>
  items.map((item, index) => ({
    item_id: item.itemDetails?.articleNumber ?? item.articleNumber,
    item_name: item.itemDetails?.displayName ?? item.displayName,
    index,
    quantity: item.quantity,
  }));
