import { Icon } from '@/icon/Icon';
import useUrlBuilder from '@/util/useUrlBuilder';
import { IconButton, styled } from '@mui/material';
import type { SxProps } from '@mui/system';
import { NavLink as RouterLink } from 'react-router-dom';

const Root = styled('div', {
  name: 'BackButton',
  slot: 'Root',
})(({ theme }) => ({
  paddingRight: theme.spacing(1),
}));

interface BackButtonProps {
  visible?: boolean;
  sx?: SxProps;
  size?: 'small' | 'medium' | 'large';
}

interface WithUrl extends BackButtonProps {
  url: string;
  onClick?: never;
}

interface WithOnClick extends BackButtonProps {
  url?: never;
  onClick: () => void;
}

function BackButton({
  visible = false,
  url,
  onClick,
  sx,
  size = 'large',
}: WithUrl | WithOnClick) {
  const { editUrl } = useUrlBuilder();
  const fallbackUrl = editUrl().deleteParam('selected').read();

  if (!visible) {
    return null;
  }

  return (
    <Root sx={sx}>
      {onClick ? (
        <IconButton onClick={onClick} size={size}>
          <Icon name="arrow_back" />
        </IconButton>
      ) : (
        <IconButton component={RouterLink} to={url ?? fallbackUrl} size={size}>
          <Icon name="arrow_back" />
        </IconButton>
      )}
    </Root>
  );
}

export default BackButton;
