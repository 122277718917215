import AppBar from '@/component/AppBar';
import Drawer from '@/component/Drawer/Drawer';
import {
  drawerWidth,
  railWidth,
  transitionIn,
  transitionOut,
} from '@/component/Drawer/layout';
import MobileDrawer from '@/component/Drawer/MobileDrawer';
import { useRail } from '@/component/Drawer/useRail';
import { usePushNotifications } from '@/feature/pushNotifications/usePushNotifications';
import { excludeProps } from '@/theme/util';
import appBarActions from '@/view/app/appBar/actions';
import Content from '@/view/routes';
import { Toolbar as MuiToolbar, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTrackUserProperties } from './feature/tracking/useTrackUserProperties';

interface MainProps {
  isSmallScreen: boolean;
  rail: boolean;
}

const Main = styled('main', {
  name: 'AppMain',
  shouldForwardProp: (propName) =>
    excludeProps(propName, ['isSmallScreen', 'rail']),
})<MainProps>(({ theme, isSmallScreen, rail }) => ({
  minHeight: '100vh',
  background: theme.palette.background.paper,
  ...(!isSmallScreen &&
    rail && {
      transition: transitionOut(theme, 'padding'),
      paddingLeft: railWidth,
    }),
  ...(!isSmallScreen &&
    !rail && {
      transition: transitionIn(theme, 'padding'),
      paddingLeft: drawerWidth,
    }),
}));

const Toolbar = styled(MuiToolbar, { name: 'AppToolbar' })({
  minHeight: '54px !important',
});

function App() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });
  const [rail, setRail] = useRail();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  useTrackUserProperties();
  usePushNotifications();

  useEffect(() => {
    if (isSmallScreen) {
      setRail(false);
    }
  }, [isSmallScreen, setRail]);

  return (
    <>
      <AppBar
        toggleDrawer={() => setMobileDrawerOpen(!mobileDrawerOpen)}
        isSmallScreen={isSmallScreen}
        actions={appBarActions}
      />
      {isSmallScreen ? (
        <MobileDrawer
          open={mobileDrawerOpen}
          close={() => setMobileDrawerOpen(false)}
        />
      ) : (
        <Drawer />
      )}
      <Main isSmallScreen={isSmallScreen} rail={rail}>
        <Toolbar />
        <div>
          <Content />
        </div>
      </Main>
    </>
  );
}

export default App;
