import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from '../../app/routing/NotFound';

const Search = lazy(() => import('./Search'));

export default () => (
  <Routes>
    <Route path="/" element={<Search />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
