import { useIsFeatureActive } from '@/feature/features';
import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NotFound } from '../app/routing/NotFound';
import {
  cartPath,
  ordersPath,
  partsPath,
  recommendationsPath,
  searchPartsPath,
  shoppinglistsPath,
} from '../pathDefinitions';
import CartRoutes from './cart/routes';
import CatalogRoutes from './catalog/routes';
import OrderRoutes from './orders/routes';
import PartRoutes, { catalogPath } from './part/routes';
import RecommendationRoutes from './recommendation/routes';
import SearchRoutes from './search/routes';
import ShoppingListRoutes from './shoppinglists/routes';

export default () => {
  const isImprovedSearchActive = useIsFeatureActive('ecom.improve-search');
  const defaultRoute = isImprovedSearchActive ? searchPartsPath : partsPath;

  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route path="/" element={<Navigate to={defaultRoute} replace />} />
        <Route path={`${partsPath}/*`} element={<PartRoutes />} />
        <Route path={`${searchPartsPath}/*`} element={<SearchRoutes />} />
        <Route path={`${catalogPath}/*`} element={<CatalogRoutes />} />
        <Route
          path={`${recommendationsPath}/*`}
          element={<RecommendationRoutes />}
        />
        <Route
          path={`${shoppinglistsPath}/*`}
          element={<ShoppingListRoutes />}
        />
        <Route path={`${ordersPath}/*`} element={<OrderRoutes />} />
        <Route path={`${cartPath}/*`} element={<CartRoutes />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};
