import { useEffect } from 'react';
import { useTranslation } from '../lang';
import { useCurrentUser } from '../user/queries';
import allRoles from '../user/roles';
import { trackUser } from './track';

export const useTrackUserProperties = () => {
  const user = useCurrentUser();
  const { language } = useTranslation();

  useEffect(
    () =>
      trackUser({
        main_role: getMainRole(user),
        all_roles: getAllRoles(user),
        user_domain: getUserDomain(user),
        sam_ui_lang: language,
      }),
    [user, language]
  );
};

const getMainRole = (user: UserDto) =>
  allRoles.find((a) => Boolean(user.roles?.find((r) => a.id === r.roleId)))
    ?.name ?? 'unknown';

const getAllRoles = (user: UserDto) =>
  user.roles?.map(
    (r) => allRoles.find((a) => a.id === r.roleId)?.name ?? 'unknown'
  ) ?? [];

const getUserDomain = (user: UserDto) => user.email?.split('@')[1] ?? 'unknown';
