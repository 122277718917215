import type { Interval } from 'date-fns';
import {
  addSeconds,
  format,
  getTime,
  isAfter,
  isBefore,
  isEqual,
  parseISO,
  startOfSecond,
} from 'date-fns';
import { notEmpty } from './array';

export function toDate(date: string | Date) {
  return typeof date === 'string' ? parseISO(date) : date;
}

interface FormatOptions {
  dateOptions?: FormatDateOptions;
  timeOptions?: FormatTimeOptions;
}

export function formatDateAndTime(
  date: string | Date,
  { dateOptions, timeOptions }: FormatOptions = {}
) {
  const d = toDate(date);

  return [formatDate(d, dateOptions), formatTime(d, timeOptions)]
    .filter(notEmpty)
    .join(' ')
    .trim();
}

export interface FormatDateOptions {
  dateStyle?: 'short' | 'shorter';
}

export function formatDate(
  date: string | Date,
  { dateStyle = 'short' }: FormatDateOptions = {}
) {
  if (dateStyle === 'shorter') {
    return format(toDate(date), 'MMM dd');
  }
  return toDate(date).toLocaleDateString(window.navigator.language, {
    dateStyle,
  });
}

export interface FormatTimeOptions {
  timeStyle?: 'short' | 'medium';
}

export function formatTime(
  date: string | Date,
  { timeStyle = 'short' }: FormatTimeOptions = {}
) {
  return toDate(date).toLocaleTimeString(window.navigator.language, {
    timeStyle,
  });
}

export function formatMonth(date: string | Date) {
  return format(toDate(date), 'MMM');
}

export function isBeforeOrEqual(dateLeft: Date, dateRight: Date) {
  return isBefore(dateLeft, dateRight) || isEqual(dateLeft, dateRight);
}

export function isAfterOrEqual(dateLeft: Date, dateRight: Date) {
  return isAfter(dateLeft, dateRight) || isEqual(dateLeft, dateRight);
}

export function isBetween({
  date,
  from,
  to,
}: {
  date: Date | undefined | null;
  from: Date;
  to: Date;
}) {
  if (!date || isNaN(date.getTime())) {
    return false;
  }

  if (isNaN(from.getTime()) || isNaN(to.getTime())) {
    throw new Error("Invalid 'from' or 'to' date");
  }

  return date.getTime() >= from.getTime() && date.getTime() <= to.getTime();
}

interface StepOptions {
  step?: number;
}

export interface EachSecondOfIntervalOptions extends StepOptions {}

export function eachSecondOfInterval(
  interval: Interval,
  options?: EachSecondOfIntervalOptions
): Date[] {
  const startDate = startOfSecond(interval.start);
  const endDate = interval.end;

  const startTime = startDate.getTime();
  const endTime = typeof endDate === 'number' ? endDate : getTime(endDate);

  if (startTime === endTime) {
    return [startDate];
  }
  if (startTime > endTime) {
    throw new RangeError('Invalid interval');
  }

  const dates: Date[] = [];

  let currentDate = startDate;

  const step = options?.step ?? 1;
  if (step < 1 || isNaN(step))
    throw new RangeError(
      '`options.step` must be a number equal to or greater than 1'
    );

  while (currentDate.getTime() <= endTime) {
    dates.push(toDate(currentDate));
    currentDate = addSeconds(currentDate, step);
  }

  return dates;
}

interface ClosestDate {
  date: Date | undefined;
  diff: number;
}

export function findClosest(date: Date, dates: Date[]) {
  return dates.reduce<ClosestDate>(
    (closest, currentDate) => {
      const diff = Math.abs(date.getTime() - currentDate.getTime());
      return closest.diff > diff ? { diff, date: currentDate } : closest;
    },
    { date: undefined, diff: Infinity }
  );
}

export function exceedsAge(
  date: Date | string | undefined,
  age: number | undefined
) {
  if (date === undefined || age === undefined || !Number.isFinite(age)) {
    return false;
  }

  const now = Date.now();
  const sampleTime = new Date(date).getTime();

  return now - sampleTime > age * 1000;
}

interface RelaxedDateRange {
  from?: Date | null;
  to?: Date | null;
}

export function isDateRangeEqual(
  a: RelaxedDateRange | undefined,
  b: RelaxedDateRange | undefined
) {
  return (
    a?.from?.getTime() === b?.from?.getTime() &&
    a?.to?.getTime() === b?.to?.getTime()
  );
}
